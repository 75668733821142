import React from 'react';
import { BrowserRouter as Router, Route, Routes/*, Link*/ } from 'react-router-dom';
import Home from '../pages/Home'; // Correct path to Home.js
import Portfolio from '../pages/Portfolio'; // Correct path to Portfolio.js
import Blog from '../pages/Blog'; // Correct path to Blog.js
import gradPhoto from '../assets/images/grad.jpg';

function App() {
  return (
    <Router>
      <div className="flex p-10 bg-[#121212] h-screen font-poppins gap-2">
        {/* Static Left Container */}
        <div className="w-1/4 bg-[#1F1B24] shadow-lg p-5 flex flex-col items-center rounded-lg">
          <img src={gradPhoto} alt="Profile" className="w-40 h-45 rounded-full mb-6" />
          <h2 className="text-white text-lg font-semibold">Hanna Jerney S. Valledor</h2>
          <h3 className="text-purple-400 text-sm mb-4">Web Developer</h3>
          <p className="text-white text-center mb-6">"I'm passionate in creating innovative websites"</p>

          <div className="space-y-3 w-full">
            {/* <a href="https://linkedin.com/in/shuggardev" className="block w-full text-center text-white bg-[#0077b5] py-2 rounded-lg">
              LinkedIn
            </a> */}
            <a href="mailto:contact@shuggardev.tech" className="block w-full text-center text-white bg-[#D44638] py-2 rounded-lg">
              Gmail
            </a>
            {/* <a href="https://github.com/shuggardev" className="block w-full text-center text-white bg-[#333] py-2 rounded-lg">
              GitHub
            </a> */}
          </div>

          {/* <button className="mt-8 py-2 px-6 bg-purple-600 text-white rounded-lg">
            View full resume
          </button> */}
        </div>

        {/* Dynamic Right Container */}
        <div className="w-3/4 bg-[#1F1B24] shadow-lg p-5 flex flex-col rounded-lg">
          {/* Navigation */}
          {/* <nav className="flex justify-end space-x-6 mb-6">
            <Link to="/" className="text-white hover:underline">Home</Link>
            <Link to="/portfolio" className="text-white hover:underline">Portfolio</Link>
            <Link to="/blog" className="text-white hover:underline">Blog</Link>
          </nav> */}

          {/* Content */}
          <div className="flex-grow content-center">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/blog" element={<Blog />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
